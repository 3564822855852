// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigMess = {
  apiKey: "AIzaSyAWNuV27Vq-E6RJTggspKSY0JsJApNjZ0I",
  authDomain: "uzuri-f2cde.firebaseapp.com",
  projectId: "uzuri-f2cde",
  storageBucket: "uzuri-f2cde.appspot.com",
  messagingSenderId: "250653523643",
  appId: "1:250653523643:web:830bb0e328b53134d8f39e",
  measurementId: "G-K0S434883R"
};

// Initialize Firebase
const appMessage = initializeApp(firebaseConfigMess,'appMessage');
export const messaging = getMessaging(appMessage);
const privateKey = "BZS9Ea-Z6zVGP25-aHrl1mHX0qpvKszWIRmqujXkSCg"
const publicKey = "BBoiu3NeI-VHSQ7xX_U21mHWcsu7bcS_R6U9uQyJJuyELTihRai-hIP9hi9tcErL7q2czIgb3ym9_haUoPHALuk"


export const getFCMToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      console.log('foreground',payload)
      resolve(payload);
    });
  });


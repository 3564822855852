import React from 'react'
import { useGetAnalyticsQuery } from '../features/orders/ordersApiSlice'

function HomePage() {
  const {data:analytics, isLoading} = useGetAnalyticsQuery()

  console.log('analytics',analytics)
  return (
    <>
    {isLoading?
    <p className='font-satoshi text-[14px]'>Loading ...</p>
    :

    <div className='flex flex-row font-satoshi'>
        <div className="flex flex-1 flex-col gap-2">
          {/* <FileUpload/> */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Total orders</p>
          <p className='font-satoshi text-2xl font-semibold'>{analytics.orders_count}</p>


            </div>

            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Total Inventory <span className='text-[14px] font-light'>(all products available stock)</span></p>
          <p className='font-satoshi text-2xl font-semibold'>{analytics.total_stock_count}</p>


            </div>

            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Total Distinct products</p>
          <p className='font-satoshi text-2xl font-semibold'>{analytics.products_count}</p>


            </div>
            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Total users</p>
          <p className='font-satoshi text-2xl font-semibold'>{analytics.users_count}</p>


            </div>
            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Total Revenue</p>
          <p className='font-satoshi text-2xl font-semibold'>$ {analytics.total_income}</p>


            </div>
            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Total products sold</p>
          <p className='font-satoshi text-2xl font-semibold'>{analytics.total_quantity_sold}</p>


            </div>
            <div className="col-span-1 flex flex-col gap-2 px-4 py-4 border rounded-md shadow-md">
          <p className='font-satoshi text-2xl font-semibold'>Inventory value</p>
          <p className='font-satoshi text-2xl font-semibold'>$ {analytics.total_product_value}</p>


            </div>
          </div>
        </div>
    </div>
    }
    </>
  )
}

export default HomePage
import { apiSlice } from "../../app/api/apiSlice";

export const categoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.query({
            query: () => '/categories',
            // providesTags:['Products']
        }),
        getCategoriesOms: builder.query({
            query: ({page}) => `/categories/oms?sort=date&page=${page}&page_size=8`,
            // providesTags:['Products']
        }),
        getSingleCategory: builder.query({
            query: (uid) => `/categories/${uid}`,
            // providesTags:['Products']

        }),

        editCategory: builder.mutation({
            query: ({catData,uid}) => ({
                url: `/categories/${uid}/update/`,
                method: 'PUT',
                body: catData,
              }),
        }),

        deleteCategory: builder.mutation({
            query: (uid) => ({
                url: `/category/${uid}/update/`,
                method: 'PUT',
                body: {deleted:true},
              }),
        }),
        // getBlogs: builder.query({
        //     query: () => '/blogs',
        //     // providesTags:['Products']

        // }),
        // getGallery: builder.query({
        //     query: () => '/gallery',
        //     // providesTags:['Products']

        // }),

        // getTeam: builder.query({
        //     query: () => '/team',
        //     // providesTags:['Products']

        // }),
        // getPartners: builder.query({
        //     query: () => '/partners',
        //     // providesTags:['Products']

        // }),
        // getCertifications: builder.query({
        //     query: () => '/certifications',
        //     // providesTags:['Products']

        // }),

        addCategory: builder.mutation({
            query: (category) => ({
                url: '/categories/create/',
                method: 'POST',
                body: category,
              }),
            //   invalidatesTags:['Products']

              
        }),
    //     contactUs: builder.mutation({
    //         query: (conatctMessage) => ({
    //             url: '/contact-us',
    //             method: 'POST',
    //             body: conatctMessage,
    //             }),
    //           invalidatesTags:['Products']

    //         }),
        
    }),
    
})


export const { 
    useGetCategoriesQuery,
    useAddCategoryMutation,
    useGetSingleCategoryQuery,
    useEditCategoryMutation,
    useGetCategoriesOmsQuery,
    useDeleteCategoryMutation,
    
} = categoriesApiSlice
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { useAddCategoryMutation, useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import { useAddProductMutation, useDeleteProductMutation, useGetMessagesQuery, useGetProductsQuery, useUpdateMessageMutation } from '../features/products/productsApiSlice'
import { ENDPOINT_ADD_PRODUCT, ENDPOINT_PRODUCTS } from '../routes'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import Pagination from '../components/shared/Pagination'
const Messages = () => {
    const [page_size, setPage_size ] = useState(5)
    const [page, setPage ] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [nextPage, setNextPage] = useState(2)
    const [prevPage, setPrevPage] = useState(1)
    const [processing, setProcessing] = useState(false)
    
    const {data,refetch} = useGetMessagesQuery({page:page})
    const [ updateEnquiry ] = useUpdateMessageMutation()
    useEffect(() => {

        setNextPage(data?.next_page)
        setPrevPage(data?.previous_page)
        setTotalPages(data?.total_pages)
        setCurrentPage(data?.current_page_number)
      },[data])
    
      const fetchNextPage = () => {
        setPage(data?.next_page)
        }
      
        const fetchPreviousPage = () => {
        setPage(data?.previous_page)
        }
      
        const fetchFirstPage = () => {
          setPage(1)    
          }
      
        const fetchLastPage = () => {
          setPage(data?.total_pages)
      
              
        }
          const handleDelete = async (item) => {
            setProcessing(item?.id)
      
            updateEnquiry({data:{
              deleted:true,
            },id:item?.id})
            .unwrap()
            .then((payload) => {
                toast.info(`Message deleted successfully`,
                {
                  position: "bottom-center",
                })
      
            //    refetch
            refetch()  
            setProcessing(false)

            })
            .catch((error) => {
                toast.error(`${error.data.name} `,
                {
                  position: "bottom-center",
                })
                console.error('rejected', error)});  
            setProcessing(false)

          }
  return (
    <>
    <div >
            <div className="flex-1 flex flex-col">
        <p className="font-satoshi text-[24px] font-medium">All contact messages</p>
        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                <table class="table-auto w-full  ">
                  <thead className='pl-2 border-b border-b-gray-200'>
                    <tr className='pl-1'>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Id #</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Email</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Name</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Subject</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Message</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Action</th>


                    </tr>
                  </thead>
                  <tbody>
                  {data?.results?.map((product) => (

                      <tr className='p-2 border-b border-b-gray-200 relative'>
                      
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'>{product?.id}</p>

                      </td>

                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>

                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            {product?.email}
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer text-gray-800'>
                          <p className='font-satoshi text-left text-gray-800 text-[12px] text-wrap'>
                            {product?.name}
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer text-gray-800'>
                          <p className='font-satoshi text-left text-gray-800 text-[12px] text-wrap'>
                            {product?.subject}
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer text-gray-800'>
                          <p className='font-satoshi text-left text-gray-800 text-[12px] text-wrap'>
                            {product?.message}
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <button onClick={() => handleDelete(product)}>
                            {processing === product?.id ?
                            "..."
                            :
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>}

                          </button>
                      </td>
                      {/* {open.includes(product.id) && (
                            <>
                              <div ref={actionRef} className={`border border-gray-200 w-40 top-8 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}>


                                <div
                                  onClick={(e) => {
                                    handleSetDeletePart()
                                    handleDeleteProduct(product.id)
                                  }}
                                  className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                                  <div className='flex flex-row gap-2'>
                                    {deletePart === 'Delete' ?
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                      </svg>
                                      :
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                      </svg>
                                    }
                                    <p className='font-satoshi text-sm text-[#BF3054]'>{deletePart}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )} */}

                    </tr>
                  ))}

                    

                  </tbody>
                </table>
              </div>
              
              <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>

    </div>
    </div>
         <ToastContainer />
</>
  )
}

export default Messages
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { useAddCategoryMutation, useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import { useAddProductMutation, useDeleteProductMutation, useGetProductsQuery } from '../features/products/productsApiSlice'
import { ENDPOINT_ADD_PRODUCT, ENDPOINT_PRODUCTS } from '../routes'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import Pagination from '../components/shared/Pagination'


function slugifyName(name) {
  return slugify(name, {
    lower: true, // convert to lowercase
    strict: true, // replace whitespace with "-"
  });
}


function ProductsPage() {

  const [page_size, setPage_size ] = useState(5)
  const [page, setPage ] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [prevPage, setPrevPage] = useState(1)
  const [sortBy, setSortBy] = useState('')
  const [sortByPrice, setSortByPrice] = useState('')



  // const { data: products, isLoading } = useGetProductsQuery({page:page, date:sortBy, price:sortByPrice})






    const {data: products,refetch} = useGetProductsQuery({page:page, date:sortBy, price:sortByPrice})
    const {data: categories,refetch:refetchCats} = useGetCategoriesQuery()
    const [ deleteProduct ] = useDeleteProductMutation()


    useEffect(() => {

      setNextPage(products?.next_page)
      setPrevPage(products?.previous_page)
      setTotalPages(products?.total_pages)
      setCurrentPage(products?.current_page_number)
    },[products])
  
    const fetchNextPage = () => {
      setPage(products?.next_page)
      }
    
      const fetchPreviousPage = () => {
      setPage(products?.previous_page)
      }
    
      const fetchFirstPage = () => {
        setPage(1)    
        }
    
      const fetchLastPage = () => {
        setPage(products?.total_pages)
    
            
          }
    const [ addProduct ] = useAddProductMutation()


    const [addProductModal, setAddProductModal] = useState(false)

    const [productName, setAddProductName] = useState('')
    const [productOldPrice, setAddProductOldPrice] = useState('')
    const [productStock, setAddProductStock] = useState('')

    const [productCategory, setAddProductCategory] = useState(null)
    const [productNewPrice, setAddProductNewPrice] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [productDescription, setAddProductDescription] = useState('')
    const [productFeatured, setAddProductFeatured] = useState(false)
    const [productThumbnailUrl, setAddProductThumbnailUrl] = useState('')
    const [allCategories, setAllCategories] = useState([])
    const [details, setDetails] = useState()
    const [thumbnails, setThumbnails] = useState()

    const [description, setDescription] = useState('')



    const handleInputChange = (event) => {
      const lines = event.target.value.split('\n');
      setDetails(lines); // This will log an array of strings, each representing a separate line of text
      setAddProductDescription(details[0])
      setDescription(event.target.value);
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      addProduct({
        name:productName,
        slug:slugifyName(productName),
        thumbnail_url:'https://placeholder.com/assets/images/150x150-2-500x500.png',
        old_price:productOldPrice,
        new_price:productNewPrice,
        description:productDescription,
        featured:productFeatured,
        category:selectedCategory,
        details:details,
        stock:productStock,
        images_urls:thumbnails

       

      })
      .unwrap()
      .then((payload) => {
          // console.log('fulfilled', payload)
          toast.info(`Product added successfull`,
          {
            position: "bottom-center",
          })
          setAddProductName('')
          setAddProductOldPrice('')
          setAddProductCategory(null)
          setAddProductNewPrice('')
          setSelectedCategory(null)
          setAddProductDescription('')
          setAddProductFeatured('')
          setAddProductThumbnailUrl('')
          setAddProductModal(false)
          refetch()
          
      })
      .catch((error) => {
          toast.error(`${error.data.name} `,
          {
            position: "bottom-center",
          })
          console.error('rejected', error)});
  
  
  
    }


    const handleDeleteProduct = async (productID) => {
      if(deletePart === 'Confirm'){

        deleteProduct(productID)
        .unwrap()
        .then((payload) => {
            // console.log('fulfilled', payload)
            toast.info(`Product deleted successfully`,
            {
              position: "bottom-center",
            })

            refetch()
            
        })
        .catch((error) => {
            toast.error(`Product could not be deleted `,
            {
              position: "bottom-center",
            })
            console.error('rejected', error)});
      }
    }

    const cancelRef = useRef()
    useEffect(() => {
     let handler = e => {
       if (cancelRef.current && !cancelRef.current.contains(e.target)) {
 
         setAddProductModal(false)
 
         console.log(cancelRef.current)
       }
     }
     document.addEventListener('mousedown', handler);
 
     return () => {
       document.removeEventListener('mousedown', handler);
     }
 
   },[]) 

   const actionRef = useRef()
   useEffect(() => {
     let handler = e => {
       if (actionRef && actionRef.current && !actionRef.current.contains(e.target)) {
         setOPen([])
         console.log(actionRef.current)
       }
     }
     document.addEventListener('mousedown', handler);
     return () => {
       document.removeEventListener('mousedown', handler);
     }
   },[])

  const [deletePart, setDeletePart] = useState('Delete')

   const handleSetDeletePart = () => {
    setDeletePart('Confirm')
    setTimeout(() => {
      setDeletePart('Delete')
    }, 2000);

  }

   useEffect(() => {

     const modifiedCategories = categories?.map((category) => ({
      ...category,
      value:category.name,
      label:category.name
     })) 
  
     setAllCategories(modifiedCategories)
   },[categories])

   const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  console.log('productFeatured', productFeatured)

  const handleChange = (productCategory) => {
    setAddProductCategory(productCategory);
    setSelectedCategory(productCategory.id)
    console.log(`Option selected:`, selectedCategory);
  }

  const [open, setOPen] = useState([]);
  const toggleOpen = productID => {
    const openState = open.slice()
    const index = openState.indexOf(productID)

    if (index >= 0) {
      openState.splice(index, 1)
      setOPen(openState)
    } else {
      openState.push(productID)
      setOPen(openState)
    }
  }
  console.log('details',details)
  console.log('description',description)
  return (
    <>
    
    
    <div className='flex flex-col bg-white'>
        <div className="flex flex-row-reverse">
            <Link
            to={ENDPOINT_ADD_PRODUCT}
             className='px-4 py-2 rounded-sm font-satoshi text-[16px] text-center bg-slate-700 
            flex flex-row gap-1
            outline-none text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                </svg>

                Add new product</Link>
        </div>
    <div className="flex flex-1 flex flex-col">
        <p className="font-satoshi text-[24px] font-medium">All Products</p>
        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                <table class="table-auto w-full  ">
                  <thead className='pl-2 border-b border-b-gray-200'>
                    <tr className='pl-1'>
                    <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Image</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Id #</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Name</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Slug</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Featured</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Stock</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Action</th>


                    </tr>
                  </thead>
                  <tbody>
                  {products?.results?.map((product) => (

                      <tr className='p-2 border-b border-b-gray-200 relative'>
                        <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <img src={product.thumbnail_url} className='h-10 w-14' alt="" />

                      </td>
                      
                      <Link to={`${ENDPOINT_PRODUCTS}/${product.id}`}>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'>{product.id}</p>

                      </td>
                      </Link>

                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                           <Link to={`${ENDPOINT_PRODUCTS}/${product.id}`}>

                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            {product.name}
                          </p>
                           </Link>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            {product.slug}
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{product.featured? 
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-green-600 w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        
                          :
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className=" text-red-500 w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                          }</p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            {product.stock}
                          </p>

                      </td>

                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <svg
                          onClick={() => toggleOpen(product.id)}
                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>


                      </td>
                      {open.includes(product.id) && (
                            <>
                              <div ref={actionRef} className={`border border-gray-200 w-40 top-8 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}>


                                <div
                                  onClick={(e) => {
                                    handleSetDeletePart()
                                    handleDeleteProduct(product.id)
                                  }}
                                  className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                                  <div className='flex flex-row gap-2'>
                                    {deletePart === 'Delete' ?
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                      </svg>
                                      :
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                      </svg>
                                    }
                                    <p className='font-satoshi text-sm text-[#BF3054]'>{deletePart}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                    </tr>
                  ))}

                    

                  </tbody>
                </table>
              </div>
              
              <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>

    </div>
</div>

{false &&
    <>
      <div className='h-screen pt-14 md:pt-2  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
        <div className='relative p-4 w-full md:w-[60%] h-full md:h-auto'>
          <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-end p-2 rounded-t '>
            </div>

            <div className='relative p-3 flex-auto'>
              <div className='px-4 pt-4 pb-3 w-full'>

                <div className="flex flex-col">
                  <div onClick={() => setAddProductModal(false)} className="">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="flex flex-row gap-2 justify-between">

                    <div className="flex flex-row gap-2">
                      <img src="" alt="" />
                      <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                        Add a new product
                      </h2>
                    </div>

                  </div>

                  <div className="flex flex-col gap-2 py-2">
                    <p className="flex flex-row py-2 text-[16px] font-satoshi text-[#333B51]">
                      {/* Please select a reason for the cancellation of this order */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                   <form  onSubmit={handleSubmit} className='w-full flex flex-col gap-2'>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="name" className='font-satoshi text-[14px]'>Product name</label>
                      <input
                      value={productName}
                      onChange={(e) => setAddProductName(e.target.value)} name='name' 
                      placeholder='Add product name'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Oldprice" className='font-satoshi text-[14px]'>Product Old price</label>
                      <input
                      value={productOldPrice}
                      onChange={(e) => setAddProductOldPrice(e.target.value)} name='Oldprice' 
                      type="number"
                      min={0} 
                      placeholder='Add product Old price'
                    
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product category</label>

                    <Select
                    onChange={handleChange}
                    options={allCategories} className='font-satoshi outline-none' />
                    </div>


                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product new price</label>
                      <input
                      value={productNewPrice}
                      onChange={(e) => setAddProductNewPrice(e.target.value)} name='Newprice' 
                      placeholder='Add product new price'
                      type="number"
                      min={0} 
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="stock" className='font-satoshi text-[14px]'>Product stock</label>
                      <input
                      value={setAddProductStock}
                      onChange={(e) => setAddProductStock(e.target.value)} name='stock' 
                      type="number"
                      min={0} 
                      placeholder='Add product stock'
                    
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>


                    <div className="flex flex-col w-full gap-1">
            <label htmlFor="description" className='font-satoshi text-[14px]'>Product details 
            <span className='text-[12px] ml-1 text-green-600'>

            (write each feature on a different line)
            </span>
            </label>
            <textarea
            rows={5} 
            value={description}
            onChange={handleInputChange}
            type="text" name="description" placeholder='Add product details' id=""
            className={`font-satoshi text-[14px] px-4 py-2 rounded-md outline-none ${description.length > 0 ? 'border-2 border':'border'}`} />
          </div>
                    {/* <div className="w-full flex flex-col gap-1">
                      <label htmlFor="description" className='font-satoshi text-[14px]'>Product description</label>
                      <textarea
                      value={productDescription}
                      onChange={(e) => setAddProductDescription(e.target.value)} name='description' 
                      placeholder='Add product description'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div> */}
                    <div className="items-start w-full flex flex-col gap-1">
                      <label htmlFor="featured" className='font-satoshi text-[14px]'>Product featured</label>
                      <input
                      value={productFeatured}
                      onChange={(e) => setAddProductFeatured(e.target.checked)} name='featured'
                      type="checkbox" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product thumbnail url</label>
                      <input
                      value={productThumbnailUrl}
                      onChange={(e) => setAddProductThumbnailUrl(e.target.value)} name='thumbnail' 
                      placeholder='Add product thumbnail url'
                    
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                  onClick={handleSubmit}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Continue</button>
                  <button
                    onClick={() => setAddProductModal(false)}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>

                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-50' />
    </>
    }
     <ToastContainer />
    </>


  )
}

export default ProductsPage
import { apiSlice } from "../../app/api/apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrders: builder.query({
            query: ({page}) => `/basketsoms/?sort=date&page=${page}&page_size=8`,
            // providesTags:['Products']
        }),
        // getOrders: builder.query({
        //     query: () => `/basketsoms/`,
        //     // providesTags:['Products']
        // }),
        getSingleOrder: builder.query({
            query: (uid) => `/baskets/oms/${uid}`,
            // providesTags:['Products']

        }),
        getAnalytics: builder.query({
            query: () => `/analytics`,
            // providesTags:['Products']

        }),
        editOrder: builder.mutation({
            query: ({statusData,id}) => ({
                url: `/basket/${id}/update/`,
                method: 'PUT',
                body: statusData,
              }),
        }),
    })
})

export const { useGetOrdersQuery, useGetSingleOrderQuery,useGetAnalyticsQuery, useEditOrderMutation,} = ordersApiSlice
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DateTimeComponent from '../components/DateTimeComponent'
import { useEditOrderMutation, useGetSingleOrderQuery } from '../features/orders/ordersApiSlice'

function OrderDetails() {
  const { orderID } = useParams()

  const navigate = useNavigate()
  const {data: order, isLoading, refetch} = useGetSingleOrderQuery(orderID)
  const [ editOrder, {isLoading:loading} ] = useEditOrderMutation()
  console.log('order',order)

  const orderStages = [
    {id:1, name:'Processing', value:'Processing', bgColor:'#FCB28E'},
    {id:2, name:'Processed', value:'Processed', bgColor:'#F0A6A5'},
    {id:3, name:'Confirmed', value:'Confirmed', bgColor:'#54D3CA'},
    {id:4, name:'Dispatched', value:'Dispatched', bgColor:'#8CD1FC'},
    {id:5, name:'Transit', value:'On the way', bgColor:'#3E5DDD'},
    {id:6, name:'Arrived', value:'Arrived', bgColor:'#B9E38D'},
    {id:7, name:'Completed', value:'Completed', bgColor:'#059D5F'},
    {id:8, name:'Cancelled', value:'Cancelled', bgColor:'#DE6972'},


    ]
    

  const actionRef = useRef()
  useEffect(() => {
    let handler = e => {
      if (actionRef && actionRef.current && !actionRef.current.contains(e.target)) {
        setOPen([])
        console.log(actionRef.current)
      }
    }
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    }
  },[])


const [open, setOPen] = useState([]);
const toggleOpen = orderID => {
const openState = open.slice()
const index = openState.indexOf(orderID)

if (index >= 0) {
openState.splice(index, 1)
setOPen(openState)
} else {
openState.push(orderID)
setOPen(openState)
}
}

const handleSubmit = async (ordID, newStage) => {
console.log('newStage',newStage)
console.log('ordID',ordID)


editOrder({statusData:{stage:newStage},id :ordID})
.unwrap()
.then((payload) => {
  console.log('fulfilled', payload)
  // window.location.reload()

 refetch()
  setOPen([])
  
})
.catch((error) => {
  console.error('rejected', error)}
  );



}

const [orderLinks, setOrderLinks] = useState([])


  useEffect(() => {

    if(!isLoading && order){
     
      const data = order?.links
      setOrderLinks(JSON.parse(data))
      
    }
  },[order, orderID])
  

  return (
    <>
    {isLoading ?
    <p>Loading ...</p>
    :

    <div className='w-full h-full flex flex-col gap-2'>
      <div className="flex flex-row gap-4">

      <button onClick={() => navigate(-1)} className='px-4 py-1 bg-gray-200 flex flex-row gap-1 justify-center rounded-md text-[14px]'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>

        Back
      </button>

      <div
                  onClick={() => open?.includes(order?.id)? setOPen([]):toggleOpen(order?.id)}
                    className={`flex flex-row justify-between rounded-md px-2 py-1 relative font-satoshi
                  ${order?.stage === 'Processing'? 'bg-[#FCB28E]'
                  :order?.stage ==='Processed'?  'bg-[#F0A6A5]'
                  :order?.stage ==='Confirmed'?  'bg-[#54D3CA]'
                  :order?.stage ==='Dispatched'?  'bg-[#8CD1FC]'
                  :order?.stage ==='Transit'?  'bg-[#3E5DDD]'
                  :order?.stage ==='Arrived'?  'bg-[#B9E38D]'
                  :order?.stage ==='Completed'?  'bg-[#059D5F]'
                  :order?.stage ==='Cancelled'?  'bg-[#DE6972]'
                  :
                  'bg-gray-200'
            }
                  `}>{order?.stage}

                  {open?.includes(order?.id)? 
                  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mt-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                
                  :
                  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mt-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                
                  }

                  {open.includes(order?.id) && (
                  <div ref={actionRef} className={`border border-gray-200 w-48 top-4 mt-4  bg-white z-50 absolute right-1 rounded-md shadow-md px-2 py-2 `}>
                    <div className="flex flex-col gap-1 w-full">

                    {orderStages?.map((stage) => (
                      <div
                      onClick={() =>handleSubmit(order?.id, stage.name)}
                       className={`px-2 py-1 cursor-pointer`}  style={{ backgroundColor: stage.bgColor }}>
                        <p className='font-satoshi text-[14px]'>{stage.value}</p>
                      </div>
                    ))}
                    </div>
                    </div>

              )}
                  </div>


      </div>
      <div className="flex flex-col md:flex-row-reverse w-full gap-2">
        <div className="w-full rounded-md border shadow-sm flex flex-col">
          <div className="font-satoshi text-[14px] flex flex-row justify-between w-full p-2">
            <p className='font-satoshi text-[14px] font-semibold'>Delivery Details</p>
            <img src={order?.delivery_payload?.icon} className='w-[2rem] h-[2rem]' alt="" />
          </div>
          <div className="border-b border w-full"></div>
          <div className="flex flex-col gap-2 px-2 py-1">
          <p className='font-satoshi text-[14px]'>Place name: {order?.delivery_place_name}</p>
          <p className='font-satoshi text-[14px]'>Address: {order?.delivery_payload?.formatted_address}</p>
          <p className='font-satoshi text-[14px]'>Place id: {order?.delivery_payload?.place_id}</p>

          <p className='font-satoshi text-[14px] '>Latitude: {order?.delivery_latitude}</p>
          <p className='font-satoshi text-[14px] '>Longitude: {order?.delivery_longitude}</p>



          </div>

        </div>

        <div className="w-full rounded-md border shadow-sm flex flex-col">
          <div className="font-satoshi text-[14px] w-full p-2">
            <p className='font-satoshi text-[14px] font-semibold'>Customer Details</p>

          </div>
          <div className="border-b border w-full"></div>
          <div className="flex flex-col gap-2 px-2 py-1">

          <p className='font-satoshi text-[14px]'>Username: {order?.profile?.user?.username}</p>
          <p className='font-satoshi text-[14px] '>Phone number: {order?.profile?.phone_number}</p>
          <p className='font-satoshi text-[14px] '>Email: {order?.profile?.user?.email}</p>
          <p className='font-satoshi text-[14px] '>Default address: {order?.profile?.default_address}</p>
          <p className='font-satoshi text-[14px] '>Home address: {order?.profile?.home_address}</p>



          </div>

        </div>

      </div>
      <div className="flex flex-col w-full gap-2">
      <p className='font-satoshi text-[14px] font-semibold'>Order items</p>

      {order?.lines?.map((line) => (

<div className="flex flex-row w-full border rounded-md p-2 mt-2 gap-2">
  <div className="">
    <img src={line?.product?.thumbnail_url} alt="" className='w-[3rem] h-[4rem]' />
  </div>
  <div className="flex flex-col gap-2">
    <p className='font-satoshi text-[14px]'>{line?.product?.name}</p>
    <p className='font-satoshi text-[14px]'>${line?.product?.new_price}</p>


  </div>
  {/* {line.} */}
</div>
))}
      </div>

      <div className="flex flex-col w-full gap-4 rounded-md border shadow-sm p-2">
      <p className='font-satoshi text-[14px] font-semibold'>Order Details</p>

<div className="flex flex-col gap-2">
<p className='font-satoshi text-[14px] font-semibold'>Total amount: ${order?.total}</p>
<p className='font-satoshi text-[14px] font-semibold'>Amount paid: ${order?.amount_paid}</p>
<p className='font-satoshi text-[14px] font-semibold'>Fully paid: {order?.paid? 'Yes':'No'}</p>
<p className='font-satoshi text-[14px] font-semibold'>Delivery distance: {order?.delivery_distance} miles</p>




</div>
      </div>

      <div className="flex flex-col w-full gap-4 rounded-md border shadow-sm p-2">
      <p className='font-satoshi text-[14px] font-semibold'>Order Payment Details</p>
      <div className="flex md:grid flex-col md:grid-cols-4 gap-2">
        <div className="col-span-1 grid grid-rows-2">
        <p className='font-satoshi text-[14px] uppercase text-gray-400'>Cart total</p>
        <p className='font-satoshi text-[14px]'>${order?.cart_total_amount}</p>
        </div>

        <div className="col-span-1 grid grid-rows-2">
        <p className='font-satoshi text-[14px] uppercase text-gray-400'>Delivery cost</p>
        <p className='font-satoshi text-[14px]'>${order?.delivery_cost}</p>
        </div>

        <div className="col-span-1 grid grid-rows-2">
        <p className='font-satoshi text-[14px] uppercase text-gray-400'>Order total</p>
        <p className='font-satoshi text-[14px]'>${order?.total}</p>
        </div>

        <div className="col-span-1 grid grid-rows-2">
        <p className='font-satoshi text-[14px] uppercase text-gray-400'>Total paid</p>
        <p className='font-satoshi text-[14px]'>${order?.amount_paid}</p>
        </div>
      </div>

      </div>

      <div className="flex flex-col w-full gap-4  rounded-md border shadow-sm p-2">
      <p className='font-satoshi text-[14px] font-semibold'>Order Transaction Details (Paypal)</p>

<div className="flex flex-col gap-2">
<p className='font-satoshi text-[14px] font-semibold'>Payment mode:  {order?.payment_mode}</p>
<p className='font-satoshi text-[14px] font-semibold'>Transaction fees: ${order?.transaction_fee}</p>
<p className='font-satoshi text-[14px] font-semibold'>Transaction state: 
<span className='uppercase font-semibold'>

{order?.transaction_state}
</span>
</p>
                    
<p className='font-satoshi text-[14px] font-semibold'>Payment created on : <DateTimeComponent createdDateTime={order?.create_time}/></p>

<p className='font-satoshi text-[14px] font-semibold'>Paypal sale id : {order?.sale_id}</p>

<div className="font-satoshi text-[14px] flex flex-col gap-1 w-full">
  {/* {orderLinks} */}
  
</div>

</div>
      </div>
    </div>
    }
    </>
  )
}

export default OrderDetails
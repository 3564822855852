import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    baseQuery:fetchBaseQuery({
        // baseUrl:'https://staging--api-enthusiastic.up.railway.app/api/v1',
        // baseUrl:'http://127.0.0.1:8000/api/v1',
        baseUrl:'https://staging--api-enthusiastic.up.railway.app/api/v1',

        
    }),
    // tagTypes:['Products'],
    endpoints: builder => ({})
})
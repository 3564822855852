import React from 'react'
import Orders from '../components/Orders'

function OrdersPage() {
  return (
    <>
    <Orders/>
    </>
  )
}

export default OrdersPage
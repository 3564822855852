import { apiSlice } from "../../app/api/apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // getProducts: builder.query({
        //     query: () => '/products/',
        //     // providesTags:['Products']
        // }),

        getProducts: builder.query({
            // query: (queryParams) => `/products/?${queryParams}`,
            query: ({page,date, price}) => `/products/?sort=date&page=${page}&page_size=10`,

            // providesTags:['Products']
        }),
        getEnquiries: builder.query({
            // query: (queryParams) => `/products/?${queryParams}`,
            query: ({page}) => `/price-enquiries/?page=${page}&page_size=10`,

            // providesTags:['Products']
        }),
        getMessages: builder.query({
            // query: (queryParams) => `/products/?${queryParams}`,
            query: ({page}) => `/contact-messages/?page=${page}&page_size=10`,

            // providesTags:['Products']
        }),
        getSingleProduct: builder.query({
            query: (id) => `/products/${id}`,
            // providesTags:['Products']

        }),
        addProduct: builder.mutation({
            query: (product) => ({
                url: '/products/create/',
                method: 'POST',
                body: product,
              }),

        }),
        updateEnquiry: builder.mutation({
            query: ({data, id}) => ({
                url: `price-enquiry/email/${id}/update/`,
                method: 'POST',
                body: data,
              }),

        }),
        updateMessage: builder.mutation({
            query: ({data, id}) => ({
                url: `contact/${id}/update/`,
                method: 'POST',
                body: data,
              }),

        }),
        updateProduct: builder.mutation({
            query: ({product, productId}) => ({
                url: `/products/${productId}/update/`,
                method: 'PUT',
                body: product,
              }),

        }),
        deleteProduct: builder.mutation({
            query: (uid) => ({
                url: `/product/${uid}/update/`,
                method: 'PUT',
                body: {deleted:true},
              }),
        }),

        
    }),
    
})


export const { 
    useGetSingleProductQuery,
    useGetProductsQuery,
    useGetEnquiriesQuery,
    useGetMessagesQuery,
    useAddProductMutation,
    useUpdateProductMutation,
    useUpdateEnquiryMutation,
    useDeleteProductMutation,
    useUpdateMessageMutation,
    
    
} = productsApiSlice
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { v4 as uuidv4 } from 'uuid';

import { getFCMToken } from "../../firebaseMessagingUtils"

const MainLayout = () => {
  const [userId, setUserId] = useState(uuidv4())
  const [isTokenFound, setTokenFound] = useState(false);
  const [fmcToken, setFCMToken] = useState("")

  let createFCMToken = async (theToken) => {
    let response = await fetch(`https://staging--api-enthusiastic.up.railway.app/api/v1/register/fcm-token/`, {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "web_id": userId,
        "fcm_token": theToken

      })
    })

    let data = await response.json()
    console.log(data)

  }

  const generateFCMToken = async () => {

    let data;
      data = await getFCMToken(setTokenFound);
      if (data) {
        console.log(data);
      }
      createFCMToken(data)
   
      
  }

  useEffect(() => {
    try {
      generateFCMToken();
    } catch (error) {
      console.error('Error generating FCM token:', error);
    }
  }, []);
    const [open, setOpen] =  useState(false)
    const [close] =  useState(false)

    // console.log('open',open)
    // console.log('close',close)


    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);
  return (
    <div className={`w-screen h-screen flex flex-col md:flex-row  ${isMobile? '':'overflow-hidden'}`}>
        <Sidebar open={open} setOpen={setOpen} />
    <div className={`flex flex-1 flex-col ${open && !isMobile ? 'w-[82%]':'w-[95.6%]'}`}>
        {/* <Topbar/> */}
        <div className="flex-1 p-4 min-h-0 overflow-auto w-full">
          <Outlet/>
        </div>
    </div>
</div>
  )
}

export default MainLayout
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { useEditCategoryMutation, useGetSingleCategoryQuery } from '../features/categories/categoriesApiSlice'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebaseUtils';



function slugifyName(name) {
    return slugify(name, {
      lower: true, // convert to lowercase
      strict: true, // replace whitespace with "-"
    });
  }


function CategoryDetailsPage() {

  const navigate = useNavigate()
    const { categoryID } = useParams()
    const catID= categoryID.toString()

    const { data: category,refetch} = useGetSingleCategoryQuery(catID)
    const [ editCategory ] = useEditCategoryMutation()

    const [editCategoryModal, setEditCategoryModal] = useState(false)
    const [categoryName, setAddCategoryName] = useState('')
    const [categoryThumbnailUrl, setAddCategoryThumbnailUrl] = useState('')

    const [fileUrl, setFileUrl] = useState(null);
    const [processing, setProcessing] = useState(false)

    const [file, setFile] = useState("");
    const [image, setImage] = useState(null);


    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setFileUrl(URL.createObjectURL(file));
    };


    const cancelRef = useRef()
    useEffect(() => {
     let handler = e => {
       if (cancelRef.current && !cancelRef.current.contains(e.target)) {
 
        setEditCategoryModal(false)
 
         console.log(cancelRef.current)
       }
     }
     document.addEventListener('mousedown', handler);
 
     return () => {
       document.removeEventListener('mousedown', handler);
     }
 
   },[]) 

   const handleDeleteCover = () => {
    setImage(null)
    setFile('')

  }

  const handleCoverChange = (event) =>  {
    setFile(event.target.files[0]);
    const uploadedImage = event.target.files[0];
    setImage(URL.createObjectURL(uploadedImage));

    }

    const handleFileUploadOnFirebaseStorage = async (uploadedImage) => {
      console.log(uploadedImage)
      const bucketName = `/categories`
      if (uploadedImage === "" || uploadedImage === null) return "";
      const storageRef = ref(storage, `/${bucketName}/${uploadedImage.name}`);
      await uploadBytesResumable(storageRef, uploadedImage);
      let downloadURL = null;
      await getDownloadURL(storageRef).then((fileURL) => downloadURL = fileURL);
      return downloadURL
    };
      

   const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true)

    let coverImageUrl = category?.thumbnail_url? category?.thumbnail_url:''
    if(image !== category?.thumbnail_url){

      const imgUrl = await handleFileUploadOnFirebaseStorage(file)
      coverImageUrl=imgUrl
      // coverImageUrl  = await Promise.all([
      //   uploadFileToFirebaseStorage(image),
      //   Promise.all(files.map(uploadFileToFirebaseStorage)),
      // ]);
    }

    editCategory({catData:{
        name:categoryName,
        slug:slugifyName(categoryName),
        thumbnail_url:coverImageUrl,
      }
        ,uid :catID})
    .unwrap()
    .then((payload) => {
        // console.log('fulfilled', payload)
        toast.info(`Category updated successfully`,
        {
          position: "bottom-center",
        })
        setAddCategoryName('')
        setFileUrl([])
        setEditCategoryModal(false)
        setProcessing(false)
        setImage('')
        setFile('')
        refetch()
        
    })
    .catch((error) => {
        toast.error(`${error.data.name} `,
        {
          position: "bottom-center",
        })
        console.error('rejected', error)});



  }

   useEffect(()=>{
    setAddCategoryName(category?.name)
    setFileUrl(category?.thumbnail_url)
    setImage(category?.thumbnail_url)


   },[category])

    console.log("categoryID",catID)

    console.log('category', category?category:'')
  return (
    <>
        <div className='flex flex-col bg-white'>
        <div className="flex flex-row justify-between">
        <div className="flex flex-row">

<button onClick={() => navigate(-1)} className='px-4 py-1 bg-gray-200 flex flex-row gap-1 justify-center rounded-md text-[14px]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
  </svg>

  Back
</button>
</div>
            <button
            onClick={() => setEditCategoryModal(true)}
             className='px-4 py-2 rounded-sm font-satoshi text-[16px] text-center bg-slate-700 
            flex flex-row gap-1
            outline-none text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>


                Edit</button>
        </div>
    <div className="flex flex-1 flex-col gap-2">
        <p className="font-satoshi text-[24px] font-medium">{category?.name}</p>
        <img src={category?.thumbnail_url} className='w-full  h-60' alt="" />
        {/* <p className="font-satoshi text-[24px] font-medium">{category}</p> */}
{/* 
        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                <table class="table-auto w-full  ">
                  <thead className='pl-2 border-b border-b-gray-200'>
                    <tr className='pl-1'>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Thumbnail url</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Id #</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Name</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Slug</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Old price</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>New price</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Stock</th>

                    </tr>
                  </thead>
                  <tbody>

                      <tr className='p-2 border-b border-b-gray-200 relative'>
                      
                
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'></p>

                      </td>
                     
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                           
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            
                          </p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'></p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'></p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'></p>

                      </td>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'></p>

                      </td>
                      

                    </tr>

                    

                  </tbody>
                </table>
              </div> */}
    </div>
</div>

{editCategoryModal &&
    <>
      <div className='h-screen pt-14 md:pt-2  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
        <div className='relative p-4 w-full md:w-[60%] h-full md:h-auto'>
          <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-end p-2 rounded-t '>
            </div>

            <div className='relative p-3 flex-auto'>
              <div className='px-4 pt-4 pb-3 w-full'>

                <div className="flex flex-col">
                  <div onClick={() => setEditCategoryModal(false)} className="">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="flex flex-row gap-2 justify-between">

                    <div className="flex flex-row gap-2">
                      <img src="" alt="" />
                      <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                        Add a new product
                      </h2>
                    </div>

                  </div>

                  <div className="flex flex-col gap-2 py-2">
                    <p className="flex flex-row py-2 text-[16px] font-satoshi text-[#333B51]">
                      {/* Please select a reason for the cancellation of this order */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                   <form onSubmit={handleSubmit}  className='w-full flex flex-col gap-2'>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="name" className='font-satoshi text-[14px]'>Category name</label>
                      <input
                      value={categoryName}
                      onChange={(e) => setAddCategoryName(e.target.value)} name='name' 
                      placeholder='Add category name'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      {/* <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Category thumbnail url</label> */}
                      {/* <input
                      value={categoryThumbnailUrl}
                      onChange={(e) => setAddCategoryThumbnailUrl(e.target.value)} name='thumbnail' 
                      placeholder='Add product thumbnail url'
                    
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' /> */}

<label class="block text-[14px] font-satoshi">Category thumbnail</label>
<div className="w-full border p-2 rounded-md">
          {image ?
                                    <div className="relative w-full h-96">

                                    <img src={image} alt="" className='w-full h-full relative' />
            
                                    <svg
                                    onClick={handleDeleteCover}
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer text-red-700 absolute top-2 right-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                :
                <>
                <input type="file" name="file"
                    id="files"
                    multiple
                //  ref={refFileInput}
                    accept="image/*"
                    onChange={handleCoverChange}
                    className='hidden' />
                <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                </label>
            </>

          }
            </div>
                    </div>
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                 onClick={handleSubmit}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>{processing?'Saving...':'Continue'}</button>
                  <button
                    onClick={() => setEditCategoryModal(false)}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>

                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-50' />
    </>
    }
      <ToastContainer />
    </>
  )
}

export default CategoryDetailsPage
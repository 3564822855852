import { useState } from "react"
import { FaRegEyeSlash,FaRegEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../constants";
import { toast } from "react-toastify";
import { ENDPOINT_DASHBOARD } from "../routes";

const Login = () => {
    const [email, setEmail] =  useState("")
    const [password, setPassword] =  useState("")
    const [showPassword, setShowPassword] =  useState(false)
    const [processing, setProcessing] =  useState(false)

    const apiKey = process.env.REACT_APP_NAME;
    const apiUrl = process.env.REACT_APP_SECUREX;
    // console.log(apiKey,apiUrl)
    const navigate = useNavigate()

    const handleLogin = async() => {
        setProcessing(true)
        if((email!==apiKey || password !== apiUrl)){
            toast.error("Invalid login credentials")
            setProcessing(false)
            return
        }
        try {
            localStorage.setItem(ACCESS_TOKEN,JSON.stringify('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'))
            setProcessing(false)
            navigate(ENDPOINT_DASHBOARD)
        } catch (error) {
            setProcessing(false)
        }
    }
  return (
       <div className="w-screen h-screen flex flex-col justify-center items-center p-4">
       <div className="bg-gray-200 rounded-md shadow-md w-full sm:w-[50%] md:w-[50%] p-4 flex flex-col gap-3">
       <p className='font-semibold flex flex-row gap-1 items-center font-satoshi text-[20px]'>
       {/* <img src={logo} className="rounded object-scale-down justify-center items-center mx-auto w-40" alt="" /> */}
        </p>
           <div className="flex flex-col gap-2">
               <label className="text-lg text-[16px] font-satoshi text-gray-800">Email</label>
               <input type="text"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
                name="email" 
                className="border rounded-sm  p-2 w-full bg-white font-satoshi focus:outline-none"
               placeholder="Enter email address" />
           </div>
           <div className="flex flex-col gap-2">
           <label className="text-lg text-[16px] font-satoshi text-gray-800">Password</label>
           <div className="flex flex-row border font-satoshi items-center rounded-md w-full">
               <input 
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               type={showPassword ? "text":"password"} 
               placeholder="Password"
               className="rounded-l-sm flex-1 w-full p-2 bg-white font-satoshi focus:outline-none"
               />
               <div 
               onClick={() => setShowPassword(!showPassword)}
               className="p-3 bg-white">
                   {showPassword ? 
                       <FaRegEye className="" />
                   :
                       <FaRegEyeSlash className=""/>
                   }
               </div>
           </div>
           {email && password && (
           <div className="flex flex-col w-full">
               <button 
               disabled={processing}
               onClick={handleLogin}
               className="bg-gray-800 rounded-sm font-satoshi w-full p-2 text-white text-center text-[16px]">
                   {processing?"processing...":"Login"}
               </button>
           </div>
           )}
           <div className=" flex flex-col justify-center items-center w-full py-3">
               {/* <Link to={REGISTER_ENDPOINT}>
               <p className="font-satoshi text-[14px]">Don't have an account? <span className="text-[#7F007D]"> Register</span></p>
               </Link> */}

           </div>
           </div>
       </div>
   </div>
  )
}

export default Login
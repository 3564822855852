import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { useGetCategoriesQuery } from '../features/categories/categoriesApiSlice';
import { useAddProductMutation, useGetSingleProductQuery, useUpdateProductMutation } from '../features/products/productsApiSlice';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebaseUtils';


function slugifyName(name) {
  return slugify(name, {
    lower: true, // convert to lowercase
    strict: true, // replace whitespace with "-"
  });
}


function ProductDetailsPage() {
    const { productID } = useParams()

    const {data: product,refetch} = useGetSingleProductQuery(productID)
    const {data: categories} = useGetCategoriesQuery()

    const [ updateProduct ] = useUpdateProductMutation()


    const [addProductModal, setAddProductModal] = useState(false)

    const [productName, setAddProductName] = useState('')
    const [productOldPrice, setAddProductOldPrice] = useState('')
    const [productStock, setAddProductStock] = useState('')

    const [productCategory, setAddProductCategory] = useState(null)
    const [productNewPrice, setAddProductNewPrice] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [currentCategory, setCurrentCategory] = useState(null)

    const [productDescription, setAddProductDescription] = useState('')
    const [productFeatured, setAddProductFeatured] = useState('')
    const [productenquire, setAddProductEnquire] = useState(false)

    const [productThumbnailUrl, setProductThumbnailUrl] = useState('')
    const [allCategories, setAllCategories] = useState([])
    const [productImages, setProductImages] = useState([])
    const [productPrevImages, setProductPrevImages] = useState([])
    const [productprevThumbnailUrl, setProductprevThumbnailUrl] = useState('')


    const [currentImage, setCurrentImage] = useState('')
    const [allImages, setAllImages] = useState()


    const [details, setDetails] = useState([])
    const [prevDetails, setPrevDetails] = useState([])
    const [currDetails, setCurrDetails] = useState([])
    const [description, setDescription] = useState('')

    const [file, setFile] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    const [files, setFiles] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);

    const [images, setImages] = useState([]);
    const [image, setImage] = useState(null);

    const [processing, setProcessing] = useState(false)


    const handleThumbnailsChange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      setFiles(selectedFiles);
      const uploadedImages = [];
      const filess = event.target.files;
      for (let i = 0; i < filess.length; i++) {
          const reader = new FileReader();
          reader.readAsDataURL(filess[i]);
          reader.onload = () => {
            uploadedImages.push(reader.result);
            if (uploadedImages.length === filess.length) {
              setImages(uploadedImages);
              // setAllImages([uploadedImages])
            }
          };
        }
    }

    const handleDeleteCover = () => {
      setImage(null)
      setFile('')

    }

    const handleDeleteThumbnail= (indexVal) => {
      setImages(images.filter((imageT, index) => index !== indexVal))
      setFiles(files.filter((file, index) => index !== indexVal))

    }


    // Handle file upload event and update state
    const handleCoverChange = (event) =>  {
      setFile(event.target.files[0]);
      const uploadedImage = event.target.files[0];
      setImage(URL.createObjectURL(uploadedImage));
  
      }





    const handleInputChange = (event) => {
      const lines = event.target.value.split(';');
      setDetails([...prevDetails, lines])
      setPrevDetails([...prevDetails, lines]) // This will log an array of strings, each representing a separate line of text
      setDescription(event.target.value)

    }


  

    const handleInputBlur = (event) => {
      const lines = event.target.value.split(';');
      setDetails([...prevDetails, lines])
      setPrevDetails([...prevDetails, lines]) // This will log an array of strings, each representing a separate line of text
      setDescription(event.target.value)

    }  

  const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        const sentence = event.target.value.trim();
  
      //   if (sentence.endsWith(".")) {
          setPrevDetails([...prevDetails, sentence]);
          setDetails([...prevDetails, sentence]);

      //   } 
        
        event.target.value = "";
        event.preventDefault();
      }
    };  

    const handleDeleteDetail = (detailValue) => {
      setPrevDetails(prevDetails.filter((detail) => detail !== detailValue));
  }

  const handleDeleteImageUrl = (imageUrl) => {
    // setProductPrevImages(productPrevImages.filter((url) => url !== imageUrl))
    // setProductImages(productImages.filter((url) => url !== imageUrl))
    setAllImages(allImages.filter((url) => url !== imageUrl))
    setImages(images.filter((url) => url !== imageUrl))

    if(imageUrl === currentImage && allImages.length>1){
      setCurrentImage(allImages[0])
    }else{
      setCurrentImage('')
    }

}

const handleDeleteThumbnailUrl = () => {
    setProductprevThumbnailUrl('')
}

const handleFileUploadOnFirebaseStorage = async (uploadedImage) => {
  console.log(uploadedImage)
  const bucketName = `/products`
  if (uploadedImage === "" || uploadedImage === null) return "";
  const storageRef = ref(storage, `/${bucketName}/${uploadedImage.name}`);
  await uploadBytesResumable(storageRef, uploadedImage);
  let downloadURL = null;
  await getDownloadURL(storageRef).then((fileURL) => downloadURL = fileURL);
  return downloadURL
};

const uploadFileToFirebaseStorage = (file) => {
  if (!file) {
    return Promise.resolve(null);
  }

  const storageRef = ref(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      null,
      reject,
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then(resolve)
          .catch(reject);
      }
    );
  });
};
const [conc,setConc] = useState('')
let thumbnailss = []


    const handleSubmit = async (e) => {
      e.preventDefault();
      setProcessing(true)

      let coverImageUrl = product?.thumbnail_url? product?.thumbnail_url:''
      if(image !== product?.thumbnail_url){

        const imgUrl = await handleFileUploadOnFirebaseStorage(file)
        coverImageUrl=imgUrl
        // coverImageUrl  = await Promise.all([
        //   uploadFileToFirebaseStorage(image),
        //   Promise.all(files.map(uploadFileToFirebaseStorage)),
        // ]);
      }

      if(images.length > 0){

        for (var i = 0; i < files.length; i++) {
          const sImage = files[i];
             const imageUrl = await handleFileUploadOnFirebaseStorage(sImage)
            

             thumbnailss.push(imageUrl)
             allImages.push(imageUrl)
             console.log('imageUrl', imageUrl)



       }
      }
      setFileUrls(thumbnailss)
      // setFileUrl(coverImageUrl)
      const concatenatedArray = allImages.concat(thumbnailss)
      setConc(concatenatedArray)
      // setAllImages([...allImages, ...thumbnailss])

      updateProduct({product:{
        name:productName,
        slug:slugifyName(productName),
        thumbnail_url:coverImageUrl,
        old_price:productOldPrice,
        new_price:productNewPrice,
        description:productDescription,
        featured:productFeatured,
        enquire:productenquire,
        category:selectedCategory?.value,
        stock:productStock,
        details:details.length > 0 ? details:prevDetails,
        images_urls:allImages 
      },productId:productID})
      .unwrap()
      .then((payload) => {
          toast.info(`product updated successfully`,
          {
            position: "bottom-center",
          })

          refetch()
          
      })
      .catch((error) => {
          toast.error(`${error.data.name} `,
          {
            position: "bottom-center",
          })
          console.error('rejected', error)});
  
  
  
    }



    useEffect(()=>{
        setAddProductName(product?.name)
        setAddProductOldPrice(product?.old_price)
        setAddProductCategory()
        setAddProductNewPrice(product?.new_price)
        setSelectedCategory()
        setAddProductDescription(product?.description)
        setAddProductFeatured(product?.featured)
        setAddProductEnquire(product?.enquire)
        setProductThumbnailUrl(product?.thumbnail_url)
        setImage(product?.thumbnail_url)

        setProductImages(product?.images)
        setCurrentImage(product?.thumbnail_url)
        setCurrentCategory(product?.category?.name)
        setPrevDetails(product?.details)
        setAddProductStock(product?.stock)
        setAllImages(product?.images_urls?.map((thumb) => thumb))
        setProcessing(false)
        // setImages([`${product?.thumbnail_url}`, ...product?.images_urls])
       
    
       },[product])

   useEffect(() => {

     const modifiedCategories = categories?.map((category) => ({
      ...category,
      value:category.id,
      label:category.name
     })) 
  
     setAllCategories(modifiedCategories)
   },[categories])




  const handleChange = (productCategory) => {
    setAddProductCategory(productCategory);
    setSelectedCategory(productCategory.id)
    console.log(`Option selected:`, selectedCategory);
  }
  const navigate = useNavigate()




  return (
    <>
    <div className='flex flex-col bg-white  overflow-y-scroll hide-scrollbar max-h-screen px-2 md:py-16 pb-40 md:px-10'>
        <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row">

<button onClick={() => navigate(-1)} className='px-4 py-1 bg-gray-200 flex flex-row gap-1 justify-center rounded-md text-[14px]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
  </svg>

  Back
</button>
</div>
        <div className="flex flex-col gap-2">
                   <form  onSubmit={handleSubmit} className='w-full flex flex-col gap-2'>
        <div className="w-full border p-2 rounded-md">
          {image ?
                                    <div className="relative w-full h-96">

                                    <img src={image} alt="" className='w-full h-full relative' />
            
                                    <svg
                                    onClick={handleDeleteCover}
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer text-red-700 absolute top-2 right-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                :
                <>
                <input type="file" name="file"
                    id="files"
                    multiple
                //  ref={refFileInput}
                    accept="image/*"
                    onChange={handleCoverChange}
                    className='hidden' />
                <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                </label>
            </>

          }
            </div>
            
            <div className="flex flex-row gap-2 overflow-x-scroll hide-scrollbar">

                {images?.map((prodImage) => (
                    <div
                    onClick={() => setCurrentImage(prodImage)}
                     className="cursor-pointer relative">
                        <img src={prodImage} className='rounded h-20 w-20 relative' alt="" />
                                    <svg
                                  onClick={() =>handleDeleteImageUrl(prodImage)}
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 cursor-pointer text-red-600 absolute top-1 right-2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                    </div>
                ))} 

{allImages?.map((prodImage) => (
                    <div
                    onClick={() => setCurrentImage(prodImage)}
                     className="cursor-pointer relative">
                        <img src={prodImage} className='rounded h-20 w-20 relative' alt="" />
                                    <svg
                                  onClick={() =>handleDeleteImageUrl(prodImage)}
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 cursor-pointer text-red-600 absolute top-1 right-2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                    </div>
                ))} 
                {/* {images?.map((prodImage) => (
                    <div
                    onClick={() => setCurrentImage(prodImage)}
                     className="cursor-pointer relative">
                        <img src={prodImage} className='rounded h-20 w-20 relative' alt="" />
                                    <svg
                                  onClick={() =>handleDeleteImageUrl(prodImage)}
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 cursor-pointer text-red-600 absolute top-1 right-2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                    </div>
                ))}  */}
                
                
                {/* {!currentImage === product?.thumbnail_url &&
                <div
                onClick={() => setCurrentImage(product?.thumbnail_url )}
                 className="cursor-pointer relative">
                    <img src={product?.thumbnail_url } className='rounded h-20 w-20 relative' alt="" />
                                <svg
                              onClick={() =>handleDeleteImageUrl(product?.thumbnail_url )}
                              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 cursor-pointer text-red-600 absolute top-1 right-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                </div>
                } */}
                    
                    {image &&
                      <label htmlFor="file-upload" class="relative cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none ">
                                 <div class="mt-1 flex justify-center rounded-md p-6">
                                    <div class="space-y-1 text-center">
                                       <div class="flex text-sm text-gray-600">
                                          <span className='font-satoshi bg-gray-300'>
                                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                             </svg>

                                          </span>
                                          <input multiple id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleThumbnailsChange} accept="image/*" />
                                       </div>
                                    </div>
                                 </div>
                              </label>
                              }
            </div>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="name" className='font-satoshi text-[14px]'>Product name</label>
                      <input
                      value={productName}
                      onChange={(e) => setAddProductName(e.target.value)} name='name' 
                      placeholder='Add product name'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Oldprice" className='font-satoshi text-[14px]'>Product Old price</label>
                      <input
                      value={productOldPrice}
                      onChange={(e) => setAddProductOldPrice(e.target.value)} name='Oldprice' 
                      type="number" 
                      min={0}
                      placeholder='Add product Old price'
                    
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product category</label>

                    <Select
                    onChange={handleChange}
                    options={allCategories}
                    placeholder={currentCategory} 
                    className='font-satoshi outline-none' />
                    </div>


                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product new price</label>
                      <input
                      value={productNewPrice}
                      onChange={(e) => setAddProductNewPrice(e.target.value)} name='Newprice' 
                      placeholder='Add product new price'
                      min={0}
                      type="number" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="stock" className='font-satoshi text-[14px]'>Product stock</label>
                      <input
                      value={productStock}
                      onChange={(e) => setAddProductStock(e.target.value)} name='stock' 
                      type="number" 
                      min={0}
                      placeholder='Add product stock'
                    
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="flex flex-col w-full gap-1">
                      {prevDetails && 
          <div className='font-satoshi text-[14px] flex flex-col gap-1'>
          {prevDetails?.map((detail) => (
            <div key={detail} className='font-satoshi text-[14px] flex flex-row gap-2'>- {detail} 
            <svg
            onClick={() =>handleDeleteDetail(detail)}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 cursor-pointer text-red-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

            </div>

          ))}

        </div>
                      }

        <div className="flex flex-col w-full gap-1">
            <label htmlFor="description" className='font-satoshi text-[14px]'>Product details 
            <span className='text-[12px] ml-1 text-green-600'>

            (make sure to press enter after each sentence)
            </span>
            </label>
            <textarea
            rows={5} 
            // value={}
            // onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            type="text" name="description" placeholder='Add product details' id=""
            className={`font-satoshi text-[14px] px-4 py-2 rounded-md outline-none border`} />
          </div>
          </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="description" className='font-satoshi text-[14px]'>Product description</label>
                      <textarea
                      value={productDescription}
                      onChange={(e) => setAddProductDescription(e.target.value)} name='description' 
                      placeholder='Add product description'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="items-start w-full flex flex-col gap-1">
                      <label htmlFor="featured" className='font-satoshi text-[14px]'>Product featured</label>
                      <input
                      checked={productFeatured}
                      value={productFeatured}
                      onChange={(e) => setAddProductFeatured(e.target.checked)} name='featured'
                      type="checkbox" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="items-start w-full flex flex-col gap-1">
                      <label htmlFor="featured" className='font-satoshi text-[14px]'>Customers to enquire price</label>
                      <input
                      checked={productenquire}
                      value={productenquire}
                      onChange={(e) => setAddProductEnquire(e.target.checked)} name='enquire'
                      type="checkbox" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                  onClick={handleSubmit}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>{!processing?'Continue':'Saving...'}</button>
                  <button
                    onClick={() => setAddProductModal(false)}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>

                </div>
        </div>


    </div>
    <ToastContainer />
    </>
  )
}

export default ProductDetailsPage
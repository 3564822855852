import { Navigate } from "react-router-dom"
import { useAuth } from "./hooks/useAuth"

export const LOGIN_ENDPOINT = "/"
export const ENDPOINT_DASHBOARD          = '/dashboard'
export const ENDPOINT_PRODUCTS           = `${ENDPOINT_DASHBOARD}/products`
export const ENDPOINT_CATEGORIES         = `${ENDPOINT_DASHBOARD}/categories`
export const ENDPOINT_ENQUIRIES         = `${ENDPOINT_DASHBOARD}/price-enquires`
export const ENDPOINT_CONTACTS         = `${ENDPOINT_DASHBOARD}/messages`

export const ENDPOINT_CUSTOMERS          = `${ENDPOINT_DASHBOARD}/customers`
export const ENDPOINT_ORDERS             = `${ENDPOINT_DASHBOARD}/orders`
export const ENDPOINT_ORDER_DETAILS      = `${ENDPOINT_ORDERS}/:orderID`
export const ENDPOINT_PRODUCT_DETAILS    = `${ENDPOINT_PRODUCTS}/:productID`
export const ENDPOINT_CATEGORy_DETAILS   = `${ENDPOINT_CATEGORIES}/:categoryID`
export const ENDPOINT_ADD_PRODUCT        = `${ENDPOINT_DASHBOARD}/add-product`



  
  export const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useAuth();
  
    if (!isAuthenticated) {
      // user is not authenticated
      return <Navigate to={LOGIN_ENDPOINT} />;
    }
  
    return <>{children}</>;
  };






// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAlqUBzTXfG1Mg3PtEg6GMDzVzjna5kKI8",
  authDomain: "uzuri-77d0f.firebaseapp.com",
  projectId: "uzuri-77d0f",
  storageBucket: "uzuri-77d0f.appspot.com",
  messagingSenderId: "614850799788",
  appId: "1:614850799788:web:24dd962580821a45ff5709",
  measurementId: "G-509PVFML43"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ENDPOINT_CATEGORIES, ENDPOINT_CUSTOMERS,ENDPOINT_ENQUIRIES, ENDPOINT_DASHBOARD, ENDPOINT_ORDERS, ENDPOINT_PRODUCTS, ENDPOINT_CONTACTS } from '../../routes'

const DASHBOARD_TAB = 'dashboard'
const PRODUCTS_TAB = 'products'
const CATEGORIES_TAB = 'categories'
const CUSTOMERS_TAB = 'customers'
const ORDERS_TAB = 'orders'
const ENQUIRIES_TAB = 'enquiries'
const MESSAGES_TAB = 'messages'





const Sidebar = ({open,setOpen}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);
  const { pathname } = useLocation()

  const [activeTab, setActiveTab] = useState(
    pathname.includes("categories") ? CATEGORIES_TAB:
    pathname.includes("products") ? PRODUCTS_TAB:
    pathname.includes("customers") ? CUSTOMERS_TAB:
    pathname.includes("orders") ? ORDERS_TAB:
    pathname.includes("enquiries") ? ENQUIRIES_TAB:

     DASHBOARD_TAB)
  console.log('pathname', pathname)
  return (
          <div className={`${open && !isMobile ? 'w-[18%]':'w-full md:w-[7.5%]'} ${isMobile && open && 'w-full md:w-[20%]'} ${isMobile && !open && 'h-20 '} bg-slate-800 border-r border-gray-200 py-5 px-3 flex flex-col gap-6 h-auto font-gothic`}>
        <div className={`w-full flex md:flex-col flex-row items-center md:items-start gap-4 justify-between`}>
        <p className={`text-[18px] text-white font-satoshi font-semibold`}>Uzuri OMS</p>

            {isMobile ? 
            <div onClick={() => setOpen(!open)} className="flex flex-row items-center md:items-start cursor-pointer">
              {open ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
          
            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
              }
        </div>
            :
            <div onClick={() => setOpen(!open)} className="flex flex-row items-center md:items-start cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>

            </div>
            }
{/*             
            {open &&
            <div className="flex flex-row items-center gap-2">
            {!isMobile ?
            <img src={logo} className="h-" alt="" />
            :
            <img src={logo} className="h-10" alt="" />
            }
            </div>} */}
        </div>  
        <div className={`w-full h-full flex flex-col gap-3 ${!open && isMobile && "hidden"}`}>
 
 
      <ul className={`flex flex-col gap-6  w-full`}>
        <Link to={ENDPOINT_DASHBOARD}>
      <li className={`${activeTab === DASHBOARD_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(DASHBOARD_TAB)}>
        <div
            className="flex flex-row items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
</svg>

            {open && <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Dashboard</p>}
        </div>
    </li>
      </Link>

      <Link to={ENDPOINT_CATEGORIES}>
      <li className={`${activeTab === CATEGORIES_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(CATEGORIES_TAB)}>
        <div
            className="flex flex-row items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
</svg>

{open && <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Categories</p>}
        </div>
    </li>
      </Link>

      <Link to={ENDPOINT_PRODUCTS}>
      <li className={`${activeTab === PRODUCTS_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(PRODUCTS_TAB)}>
        <div
            className="flex flex-row items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
</svg>

           {open &&  <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Products</p>}
        </div>
    </li>
      </Link>

      {/* <Link to={ENDPOINT_CUSTOMERS}>
      <li className={`${activeTab === CUSTOMERS_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(CUSTOMERS_TAB)}>
        <div
            className=""
        >
            <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Customers</p>
        </div>
    </li>
    </Link> */}

    <Link to={ENDPOINT_ORDERS}>
      <li className={`${activeTab === ORDERS_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(ORDERS_TAB)}>
        <div
            className="flex flex-row items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
</svg>

            {open && <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Orders</p>}
        </div>
    </li>
    </Link>
    <Link to={ENDPOINT_ENQUIRIES}>
      <li className={`${activeTab === ENQUIRIES_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(ENQUIRIES_TAB)}>
        <div
            className="flex flex-row items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
</svg>

           {open &&  <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Enquiries</p>}
        </div>
    </li>
      </Link>

      <Link to={ENDPOINT_CONTACTS}>
      <li className={`${activeTab === MESSAGES_TAB ? 'text-black bg-white ' : 'text-white'}  rounded-sm w-full cursor-pointer px-4 py-2`}
        onClick={() => setActiveTab(MESSAGES_TAB)}>
        <div
            className="flex flex-row items-center gap-2"
        >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
</svg>


           {open &&  <p className={`text-[14px] md:text-[18px]  font-satoshi`}>Messages</p>}
        </div>
    </li>
      </Link>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
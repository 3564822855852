import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import Pagination from '../components/shared/Pagination';
import { useAddCategoryMutation, useDeleteCategoryMutation, useEditCategoryMutation, useGetCategoriesOmsQuery, useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import { ENDPOINT_CATEGORIES } from '../routes';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebaseUtils';



function slugifyName(name) {
    return slugify(name, {
      lower: true, // convert to lowercase
      strict: true, // replace whitespace with "-"
    });
  }

function CategoriesPage() {

  const [ editCategory ] = useEditCategoryMutation()

  const [page_size, setPage_size ] = useState(5)
  const [page, setPage ] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [prevPage, setPrevPage] = useState(1)
  const [sortBy, setSortBy] = useState('')
  const [sortByPrice, setSortByPrice] = useState('')

  const [file, setFile] = useState("");
  const [image, setImage] = useState(null);



    const {data: categories, refetch} = useGetCategoriesOmsQuery({page:page})
    const [ addCategory ] =useAddCategoryMutation()

    const [ deleteCategory ] = useDeleteCategoryMutation()




    const uploadFileToFirebaseStorage = (file) => {
      if (!file) {
        return Promise.resolve(null);
      }
  
      const storageRef = ref(storage, `/categories/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          reject,
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then(resolve)
              .catch(reject);
          }
        );
      });
    };  


    useEffect(() => {

      setNextPage(categories?.next_page)
      setPrevPage(categories?.previous_page)
      setTotalPages(categories?.total_pages)
      setCurrentPage(categories?.current_page_number)
    },[categories])
  
    const fetchNextPage = () => {
      setPage(categories?.next_page)
      }
    
      const fetchPreviousPage = () => {
      setPage(categories?.previous_page)
      }
    
      const fetchFirstPage = () => {
        setPage(1)    
        }
    
      const fetchLastPage = () => {
        setPage(categories?.total_pages)
    
            
          }

    const [addCategoryModal, setAddCategoryModal] = useState(false)
    const [processing, setProcessing] = useState(false)

    const [categoryName, setAddCategoryName] = useState('')
    const [categoryThumbnailUrl, setAddCategoryThumbnailUrl] = useState('')

    const [fileUrl, setFileUrl] = useState(null);

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setFileUrl(URL.createObjectURL(file));
    };

    const handleApprovalChange = async (categoryID) => {
      if(deletePart === 'Confirm'){

        deleteCategory(categoryID)
        .unwrap()
        .then((payload) => {
            // console.log('fulfilled', payload)
            toast.info(`Category deleted successfull`,
            {
              position: "bottom-center",
            })
           
            refetch()
            
        })
        .catch((error) => {
            toast.error(`Category could not be deleted `,
            {
              position: "bottom-center",
            })
            console.error('rejected', error)});
      }
    }

    const handleCoverChange = (event) =>  {
      setFile(event.target.files[0]);
      const uploadedImage = event.target.files[0];
      setImage(URL.createObjectURL(uploadedImage));
  
      }

      const handleDeleteCover = () => {
        setImage(null)
        setFile('')

      }  

      console.log('fileUrl',fileUrl)
    const handleSubmit = async(e) => {
        e.preventDefault();
        if (fileUrl === null) {
          toast.error(`Thumbnail is required `,
          {
            position: "bottom-center",
          })
        setProcessing(false)

          
        } 
        setProcessing(true)

        const coverImageUrl = await Promise.all([
          uploadFileToFirebaseStorage(file)
        ]);
        console.log('coverImageUrl',coverImageUrl[0])

      
        addCategory({
          name:categoryName,
          slug:slugifyName(categoryName),
          thumbnail_url:coverImageUrl[0],
        })
        .unwrap()
        .then((payload) => {
            // console.log('fulfilled', payload)
            toast.info(`Category added successfully`,
            {
              position: "bottom-center",
            })
            setAddCategoryName('')
            setFileUrl([])
            setAddCategoryModal(false)
            setProcessing('')
            setFile('')
            setFileUrl('')
            setImage('')

            refetch()
            
        })
        .catch((error) => {
            // toast.error(`${error.data.name} `,
            toast.error(`An error occured make sure to include all product data `,

            {
              position: "bottom-center",
            })
            console.error('rejected', error)});
    
    
    
      }


    const cancelRef = useRef()
    useEffect(() => {
     let handler = e => {
       if (cancelRef.current && !cancelRef.current.contains(e.target)) {
 
         setAddCategoryModal(false)
 
         console.log(cancelRef.current)
       }
     }
     document.addEventListener('mousedown', handler);
 
     return () => {
       document.removeEventListener('mousedown', handler);
     }
 
   },[])
   
   const actionRef = useRef()
   useEffect(() => {
     let handler = e => {
       if (actionRef && actionRef.current && !actionRef.current.contains(e.target)) {
         setOPen([])
         console.log(actionRef.current)
       }
     }
     document.addEventListener('mousedown', handler);
     return () => {
       document.removeEventListener('mousedown', handler);
     }
   },[])

  const [deletePart, setDeletePart] = useState('Delete')

   const handleSetDeletePart = () => {
    setDeletePart('Confirm')
    setTimeout(() => {
      setDeletePart('Delete')
    }, 2000);

  }


  const [open, setOPen] = useState([]);
  const toggleOpen = categoryID => {
    const openState = open.slice()
    const index = openState.indexOf(categoryID)

    if (index >= 0) {
      openState.splice(index, 1)
      setOPen(openState)
    } else {
      openState.push(categoryID)
      setOPen(openState)
    }
  }

   console.log(fileUrl)
   console.log(categories)


  
   const handleSubmitEdit = (category) => {
    // if(deletePart === 'Confirm'){

      editCategory({catData:{
          deleted:true,
          slug:slugifyName(category.slug),

        }
          ,uid :category.id})
      .unwrap()
      .then((payload) => {
          console.log('fulfilled', payload)
          toast.info(`Category updated successfully`,
          {
            position: "bottom-center",
          })
          setOPen([])
          // window.location.reload()
          refetch()
          
      })
      .catch((error) => {
          toast.error(`${error.data.name} `,
          {
            position: "bottom-center",
          })
          console.error('rejected', error)});
    // }




  } 
  console.log('file', file)

  return (
    <>
        <>
    
    
    <div className='flex flex-col bg-white'>
        <div className="flex flex-row-reverse">
            <button
            onClick={() => setAddCategoryModal(true)}
             className='px-4 py-2 rounded-sm font-satoshi text-[16px] text-center bg-slate-700 
            flex flex-row gap-1
            outline-none text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                </svg>

                Add new category</button>
        </div>
    <div className="flex flex-1 flex-col">
        <p className="font-satoshi text-[24px] font-medium">All Categories</p>
        
        {/* <div className="flex flex-col"> */}
        {/* <div className="flex flex-col w-full "> */}
          
              <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                <table class="table-auto w-full  ">
                  <thead className='pl-2 border-b border-b-gray-200'>
                    <tr className='pl-1'>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Id #</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Thumbnail</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Name</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Products</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {categories?.results?.map((category) => (

                      <tr className='p-2 border-b border-b-gray-200 relative'>
                      
                      <Link to={`${ENDPOINT_CATEGORIES}/${category.id}`}>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
            
                          <p className='font-satoshi text-left text-[#030A1A]'>{category.id}</p>

                      </td>
                      </Link>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <img src={category.thumbnail_url} className='w-14 h-14 rounded' alt="" />
                          {/* <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{category.thumbnail_url}</p> */}

                      </td>
                      <Link to={`${ENDPOINT_CATEGORIES}/${category.id}`}>

                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            {category.name}
                          </p>

                      </td>
                      </Link>
                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                            {category.product_count} {category.product_count ===1? 'Item':'Items'}
                          </p>

                      </td>

                      <td
                        className='p-4 text-xs md:text-sm cursor-pointer '>
                          <svg
                          onClick={() => toggleOpen(category.id)}
                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>


                      </td>
                      {open.includes(category.id) && (
                        <>
                          <div ref={actionRef} className={`border border-gray-200 w-40 top-8 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}>


                            <div
                              onClick={(e) => {
                                handleSetDeletePart()
                                handleApprovalChange(category.id)
                              }}
                              className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                              <div className='flex flex-row gap-2'>
                                {deletePart === 'Delete' ?
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                  </svg>
                                  :
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                  </svg>
                                }
                                <p className='font-satoshi text-sm text-[#BF3054]'>{deletePart}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      

                    </tr>
                  ))}

                    

                  </tbody>
                </table>
                <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>
              </div>
            
        {/* </div> */}
      {/* </div> */}
    </div>
</div>

{addCategoryModal &&
    <>
      <div className='h-screen pt-14 md:pt-2  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
        <div className='relative p-4 w-full md:w-[60%] h-full md:h-auto'>
          <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-end p-2 rounded-t '>
            </div>

            <div className='relative p-3 flex-auto'>
              <div className='px-4 pt-4 pb-3 w-full'>

                <div className="flex flex-col">
                  <div onClick={() => setAddCategoryModal(false)} className="">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="flex flex-row gap-2 justify-between">

                    <div className="flex flex-row gap-2">
                      <img src="" alt="" />
                      <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                        Add a new product
                      </h2>
                    </div>

                  </div>

                  <div className="flex flex-col gap-2 py-2">
                    <p className="flex flex-row py-2 text-[16px] font-satoshi text-[#333B51]">
                      {/* Please select a reason for the cancellation of this order */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                   <form onSubmit={handleSubmit}  className='w-full flex flex-col gap-2'>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="name" className='font-satoshi text-[14px]'>Category name</label>
                      <input
                      value={categoryName}
                      onChange={(e) => setAddCategoryName(e.target.value)} name='name' 
                      placeholder='Add category name'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">

<label class="block text-[14px] font-satoshi">Category thumbnail</label>
              <div>
                    {fileUrl || image ?
                    <div className="relative w-full h-96">

                        <img src={fileUrl?fileUrl:image} alt="" className='w-full h-full relative' />

                        <svg
                        onClick={handleDeleteCover}
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer text-red-700 absolute top-2 right-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                        :
                        <>
                            <input type="file" name="file"
                                id="files"
                                multiple
                            //  ref={refFileInput}
                                accept="image/*"
                                onChange={handleCoverChange}
                                className='hidden' />
                            <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                </svg>
                            </label>
                        </>
                    }

                </div>
                    </div>
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                 onClick={handleSubmit}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>{processing? 'Saving...':'Continue'}</button>
                  <button
                    onClick={() => setAddCategoryModal(false)}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>

                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-50' />
    </>
    }
    </>
    <ToastContainer />

    </>
  )
}

export default CategoriesPage
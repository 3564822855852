import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useEditOrderMutation, useGetOrdersQuery } from '../features/orders/ordersApiSlice'
import { ENDPOINT_ORDERS } from '../routes'
import DateTimeComponent from './DateTimeComponent';
import Pagination from './shared/Pagination';


function formatDate(date) {
  const now = new Date();
  const diff = now - date;

  // convert diff to days, hours, minutes
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  // return formatted date string
  if (days >= 30) {
    const months = Math.floor(days / 30);
    return ` ${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days >= 14) {
    return ` 2 weeks ago`;
  } else if (days >= 7) {
    return ` 1 week ago`;
  } else if (days >= 2) {
    return ` ${days} days ago`;
  } else if (days === 1) {
    return ` yesterday`;
  } else if (hours >= 6) {
    return ` ${hours} hours ago`;
  } else if (hours >= 1) {
    return ` ${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes >= 30) {
    return ` 30 minutes ago`;
  } else if (minutes >= 1) {
    return ` ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return ` just now`;
  }
}

function Orders() {
  
  const [page_size, setPage_size ] = useState(5)
  const [page, setPage ] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [prevPage, setPrevPage] = useState(1)
  const [sortBy, setSortBy] = useState('')
  const [sortByPrice, setSortByPrice] = useState('')
  const  {data:orders, isLoading:loading, refetch} = useGetOrdersQuery({page:page})

  const [ editOrder, {isLoading} ] = useEditOrderMutation()




  const orderStages = [
    {id:1, name:'Processing', value:'Processing', bgColor:'#FCB28E'},
    {id:2, name:'Processed', value:'Processed', bgColor:'#F0A6A5'},
    {id:3, name:'Confirmed', value:'Confirmed', bgColor:'#54D3CA'},
    {id:4, name:'Dispatched', value:'Dispatched', bgColor:'#8CD1FC'},
    {id:5, name:'Transit', value:'On the way', bgColor:'#3E5DDD'},
    {id:6, name:'Arrived', value:'Arrived', bgColor:'#B9E38D'},
    {id:7, name:'Completed', value:'Completed', bgColor:'#059D5F'},
    {id:8, name:'Cancelled', value:'Cancelled', bgColor:'#DE6972'},


    ]
    

  useEffect(() => {

    setNextPage(orders?.next_page)
    setPrevPage(orders?.previous_page)
    setTotalPages(orders?.total_pages)
    setCurrentPage(orders?.current_page_number)
  },[orders])

  const fetchNextPage = () => {
    setPage(orders?.next_page)
    }
  
    const fetchPreviousPage = () => {
    setPage(orders?.previous_page)
    }
  
    const fetchFirstPage = () => {
      setPage(1)    
      }
  
    const fetchLastPage = () => {
      setPage(orders?.total_pages)
  
          
        }


        const actionRef = useRef()
        useEffect(() => {
          let handler = e => {
            if (actionRef && actionRef.current && !actionRef.current.contains(e.target)) {
              setOPen([])
              console.log(actionRef.current)
            }
          }
          document.addEventListener('mousedown', handler);
          return () => {
            document.removeEventListener('mousedown', handler);
          }
        },[])
     
    
  const [open, setOPen] = useState([]);
  const toggleOpen = orderID => {
    const openState = open.slice()
    const index = openState.indexOf(orderID)

    if (index >= 0) {
      openState.splice(index, 1)
      setOPen(openState)
    } else {
      openState.push(orderID)
      setOPen(openState)
    }
  }

  const handleSubmit = async (ordID, newStage) => {
    console.log('newStage',newStage)
    console.log('ordID',ordID)


    editOrder({statusData:{stage:newStage},id :ordID})
    .unwrap()
    .then((payload) => {
        console.log('fulfilled', payload)
        // window.location.reload()

       refetch()
        setOPen([])
        
    })
    .catch((error) => {
        console.error('rejected', error)}
        );



  }

  const handleUpdateStatus = (oID, newStage) => {
    fetch(`/basket/${oID}/update/`, {
      method: 'PUT', // or 'PATCH' if partial update is allowed
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        status: newStage
      })
    })
    .then(response => response.json())
    .then(data => console.log(data));
  };
  console.log('orders', orders)
  return (
    <>
    {loading? 
    
    <p>Loading ....</p>
    :
    <div className='w-full h-full flex flex-col gap-2 font-satoshi'>
    <p className='text-[24px]'>Orders</p>
    <div className="flex flex-col w-full">
    <div className="flex flex-col gap-2">
      
      <div className="pt-2 w-full overflow-x-scroll hide-scrollbar border rounded-lg  mt-4">
        <table class="table-auto w-full  ">
          <thead className='pl-2 border-b border-b-gray-200'>
            <tr className='pl-1'>
             
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>ID #</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Date</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Customer</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Items</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Total</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Amount paid</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Fully paid</th>
              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Status</th>

              <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Delivery status</th>
            </tr>
          </thead>
          {/* ACTIONS -edit delete */}
          <tbody>
            {orders?.results?.map((order) => (

              <tr className='p-2 border-b border-b-gray-200 relative'>
             
              <Link to={`${ENDPOINT_ORDERS}/${order?.id}`}>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer flex flex-row'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-gray-500 w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                  <p className='font-satoshi text-left text-[#030A1A]'>EA-{order?.id}</p>

              </td>
              </Link>
             
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                    <DateTimeComponent createdDateTime={order?.updated_at}/>
                    </p>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{formatDate(new Date(order?.updated_at))}</p>


              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer flex flex-col gap-1 '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{order?.profile?.user?.username}</p>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{order?.profile?.phone_number}</p>


              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{order.lines?.length} {order.lines?.length ===1? 'Item':'Items'}</p>

              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>${order?.total}</p>

              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>${order?.amount_paid}</p>

              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{order?.paid? 'Yes':'No'}</p>

              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{order?.status}</p>

              </td>
              <td
                className='p-4 text-xs md:text-sm cursor-pointer '>
                  <div
                  onClick={() => open?.includes(order?.id)? setOPen([]):toggleOpen(order?.id)}
                    className={`flex flex-row justify-between rounded-md px-2 py-1
                  ${order?.stage === 'Processing'? 'bg-[#FCB28E]'
                  :order?.stage ==='Processed'?  'bg-[#F0A6A5]'
                  :order?.stage ==='Confirmed'?  'bg-[#54D3CA]'
                  :order?.stage ==='Dispatched'?  'bg-[#8CD1FC]'
                  :order?.stage ==='Transit'?  'bg-[#3E5DDD]'
                  :order?.stage ==='Arrived'?  'bg-[#B9E38D]'
                  :order?.stage ==='Completed'?  'bg-[#059D5F]'
                  :order?.stage ==='Cancelled'?  'bg-[#DE6972]'
                  :
                  'bg-gray-200'
            }
                  `}>{order?.stage}

                  {open?.includes(order?.id)? 
                  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                
                  :
                  <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
                
                  }
                  </div>

              </td>
              {open.includes(order?.id) && (
                  <div ref={actionRef} className={`border border-gray-200 w-48 top-8 mt-4  bg-white z-50 absolute right-4 rounded-md shadow-md px-2 py-2 `}>
                    <div className="flex flex-col gap-1 w-full">

                    {orderStages?.map((stage) => (
                      <div
                      onClick={() =>handleSubmit(order?.id, stage.name)}
                       className={`px-2 py-1 cursor-pointer`}  style={{ backgroundColor: stage.bgColor }}>
                        <p className='font-satoshi text-[14px]'>{stage.value}</p>
                      </div>
                    ))}
                    </div>
                    </div>

              )}

            </tr>
            ))}
      

          </tbody>
        </table>
      </div>
        <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>
    
</div>
    </div>
</div>
    }
    </>
  )
}

export default Orders
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useAddCategoryMutation, useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import { useAddProductMutation, useDeleteProductMutation, useGetProductsQuery } from '../features/products/productsApiSlice'
import { ENDPOINT_ADD_PRODUCT, ENDPOINT_PRODUCTS } from '../routes'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'slugify';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebaseUtils';


function slugifyName(name) {

  return slugify(name, {
    lower: true, // convert to lowercase
    strict: true, // replace whitespace with "-"
  });
}


function AddProductPage() {

    const {data: categories} = useGetCategoriesQuery()
    const [ addProduct ] = useAddProductMutation()
    const PAYPAL_PERCENTAGE = 0.035
    const [addProductModal, setAddProductModal] = useState(false)

    const [productName, setAddProductName] = useState('')
    const [productOldPrice, setAddProductOldPrice] = useState(0)
    const [productStock, setAddProductStock] = useState(0)

    const [productCategory, setAddProductCategory] = useState(null)
    const [productNewPrice, setAddProductNewPrice] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [productDescription, setAddProductDescription] = useState('')
    const [productFeatured, setAddProductFeatured] = useState(false)
    const [productThumbnailUrl, setAddProductThumbnailUrl] = useState('')
    const [allCategories, setAllCategories] = useState([])
    const [details, setDetails] = useState('')
    const [thumbnails, setThumbnails] = useState()
    const [productenquire, setAddProductEnquire] = useState(false)

    const [description, setDescription] = useState('')

    const [file, setFile] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    const [files, setFiles] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);

    const [images, setImages] = useState([]);
    const [image, setImage] = useState(null);

    const [processing, setProcessing] = useState(false)
    const [processingData, setProcessingData] = useState(false)



    const handleThumbnailsChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
        const uploadedImages = [];
        const filess = event.target.files;
        for (let i = 0; i < filess.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(filess[i]);
            reader.onload = () => {
              uploadedImages.push(reader.result);
              if (uploadedImages.length === filess.length) {
                setImages(uploadedImages);
              }
            };
          }
      }

      const handleDeleteCover = () => {
        setImage(null)
        setFile('')

      }

      const handleDeleteThumbnail= (indexVal) => {
        setImages(images.filter((imageT, index) => index !== indexVal))
        setFiles(files.filter((file, index) => index !== indexVal))

      }

      const handleThumbnailsUpload = () => {
        if (!files.length) {
            alert("Please upload at least one image first!");
            return;
          }
      
          const storageRef = ref(storage);
      
          const promises = files.map((file) => {
            const fileRef = ref(storageRef, `/files/${file.name}`);
            const uploadTask = uploadBytesResumable(fileRef, file);
            return uploadTask;
          });
      
          Promise.all(promises)
            .then(() => {
              const downloadUrlPromises = promises.map((uploadTask) => {
                return getDownloadURL(uploadTask.snapshot.ref);
              });
      
              Promise.all(downloadUrlPromises).then((downloadUrls) => {
                setFileUrls(downloadUrls);
              });
            })
            .catch((err) => console.log(err));
      };


          // progress
    const [percent, setPercent] = useState(0);

    // Handle file upload event and update state
    const handleCoverChange = (event) =>  {
        setFile(event.target.files[0]);
        const uploadedImage = event.target.files[0];
        setImage(URL.createObjectURL(uploadedImage));
    
        }
        
    const handleUploadCoverImage = () => {
        if (!file) {
            toast.error(`Add a cover image  `,
            {
              position: "bottom-center",
            })
        }
  
        const storageRef = ref(storage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
        // update progress
                setPercent(percent);
                },
        (err) => console.log(err),
        () => {
    // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    setFileUrl(url)
                    });
                }
            );
        };

        const uploadFileToFirebaseStorage = (file) => {
            if (!file) {
              return Promise.resolve(null);
            }
        
            const storageRef = ref(storage, `/products/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
        
            return new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                null,
                reject,
                () => {
                  getDownloadURL(uploadTask.snapshot.ref)
                    .then(resolve)
                    .catch(reject);
                }
              );
            });
          };


    const handleInputChange =  (event) => {
        const lines = event.target.value.split('\n');
        setDetails(lines); // This will log an array of strings, each representing a separate line of text
        setAddProductDescription(details[0])
        setDescription(event.target.value);
      }

      console.log('image',image)
      console.log('fileUrl',fileUrl)

      const handleSubmit = async (e) => {
          e.preventDefault();
          if (image === null) {
            toast.error(`Cover image is required `,
            {
              position: "bottom-center",
            })
          setProcessing(false)
  
            
          } 
            setProcessing(true)

          const [coverImageUrl, thumbnailUrls] = await Promise.all([
            uploadFileToFirebaseStorage(file),
            Promise.all(files.map(uploadFileToFirebaseStorage)),
          ]);
        // if (fileUrl !== '' && fileUrls.length > 0){
        //     setProcessing(false)
        // }
        // setProcessingData(true)

        // if(!processing && processingData){

            addProduct({
              name:productName,
              slug:slugifyName(productName),
              thumbnail_url:coverImageUrl,
              old_price:productOldPrice,
              new_price:productNewPrice,
              description:productDescription,
              featured:productFeatured,
              enquire:productenquire,
              category:selectedCategory,
              details:details,
              stock:productStock,
              images_urls:thumbnailUrls
      
             
      
            })
            .unwrap()
            .then((payload) => {
                // console.log('fulfilled', payload)
                toast.info(`Product added successfully`,
                {
                  position: "bottom-center",
                })
                setAddProductName('')
                setAddProductOldPrice(0)
                setAddProductCategory(null)
                setAddProductNewPrice(0)
                setSelectedCategory(null)
                setAddProductDescription('')
                setAddProductFeatured('')
                setAddProductThumbnailUrl('')
                setAddProductEnquire(false)
                setAddProductModal(false)
                setFile('')
                setFileUrl('')
                setFiles([])
                setFileUrls([])
                setImages([])
                setImage('')
                setDetails('')
                setDetails('')
                setAddProductStock(0)
                setAddProductFeatured(false)
                setProcessing(false)
                
            })
            .catch((error) => {
                toast.error(`An error occured make sure to add all fields `,
                {
                  position: "bottom-center",
                })
                console.error('rejected', error)});
        
        // }

    
    
      }


   useEffect(() => {

    const modifiedCategories = categories?.map((category) => ({
     ...category,
     value:category.name,
     label:category.name
    })) 
 
    setAllCategories(modifiedCategories)
  },[categories])


  const handleChange = (productCategory) => {
    setAddProductCategory(productCategory);
    setSelectedCategory(productCategory.id)
    console.log(`Option selected:`, selectedCategory);
  }
  const navigate = useNavigate()


  const handleAmountChange = (price) => {
    const inputAmount = parseFloat(price) || 0; // Get input value
    const newAmount = inputAmount + (inputAmount * 30) / 100; // Add 30%
    return newAmount.toFixed(2); // Update amount with 3.5% added
  };

  console.log('processing', processing)
  console.log('processingData', processingData)


  return (
    <>
        <div className='px-4 pt-4 pb-3 w-full'>

                <div className="flex flex-col">
                <div className="flex flex-row">

<button onClick={() => navigate(-1)} className='px-4 py-1 bg-gray-200 flex flex-row gap-1 justify-center rounded-md text-[14px]'>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
  </svg>

  Back
</button>
</div>


                  <div className="flex flex-col gap-2">
                   <form  onSubmit={handleSubmit} className='w-full flex flex-col gap-2'>
                  <div className="flex flex-col gap-2 py-2">
                  <label htmlFor="name" className='font-satoshi text-[14px]'>Product cover image <span className='text-red-800'>(required *)</span></label>

                  <div>
                    {fileUrl || image ?
                    <div className="relative w-full h-96">

                        <img src={fileUrl?fileUrl:image} alt="" className='w-full h-full relative' />

                        <svg
                        onClick={handleDeleteCover}
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer text-red-700 absolute top-2 right-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                        :
                        <>
                            <input type="file" name="file"
                                id="files"
                                multiple
                            //  ref={refFileInput}
                                accept="image/*"
                                onChange={handleCoverChange}
                                className='hidden' />
                            <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                </svg>
                            </label>
                        </>
                    }

                </div>
                  </div>

              {image &&   
                    <div className="flex flex-col w-full gap-1">
                      <label htmlFor="name" className='font-satoshi text-[14px]'>Product images</label>

                 <div className="flex flex-row w-full gap-5 overflow-x-scroll hide-scrollbar">
                                {fileUrls.length > 0  ? 

                                fileUrls.map((url, index) => (
                                    <img src={url} key={index} className='w-20 h-20 rounded '/>
                                )):
                                images.map((image, index) => (
                                    <div className="w-20 h-20 relative ">

                                        <img src={image} key={index} className='w-full h-full relative rounded object-cover' />
                                        <svg 
                                        onClick={() => handleDeleteThumbnail(index)}
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer text-red-700 absolute top-2 right-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                    </div>
                                ))
                                    }
                                <label htmlFor="file-upload" class="relative cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none ">
                                 <div class="mt-1 flex justify-center rounded-md p-6">
                                    <div class="space-y-1 text-center">
                                       <div class="flex text-sm text-gray-600">
                                          <span className='font-satoshi bg-gray-300'>
                                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                             </svg>

                                          </span>
                                          <input multiple id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleThumbnailsChange} accept="image/*" />
                                       </div>
                                    </div>
                                 </div>
                              </label>
                    </div>
                    </div>
                    }

                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="name" className='font-satoshi text-[14px]'>Product name</label>
                      <input
                      value={productName}
                      onChange={(e) => setAddProductName(e.target.value)} name='name' 
                      placeholder='Add product name'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Oldprice" className='font-satoshi text-[14px]'>Product Old price</label>
                      <input
                      value={productOldPrice}
                      onChange={(e) => setAddProductOldPrice(e.target.value)} name='Oldprice' 
                      type="number"
                      min={0} 
                      placeholder='Add product Old price'
                    
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product category</label>

                    <Select
                    onChange={handleChange}
                    options={allCategories} className='font-satoshi outline-none' />
                    </div>


                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product new price</label>
                      <input
                      value={productNewPrice}
                      onChange={(e) => setAddProductNewPrice(e.target.value)} name='Newprice' 
                      placeholder='Add product new price'
                      type="number"
                      min={0} 
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="stock" className='font-satoshi text-[14px]'>Product stock</label>
                      <input
                      value={productStock}
                      onChange={(e) => setAddProductStock(e.target.value)} name='stock' 
                      type="number"
                      min={0} 
                      placeholder='Add product stock'
                    
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>


                    <div className="flex flex-col w-full gap-1">
            <label htmlFor="description" className='font-satoshi text-[14px]'>Product details 
            <span className='text-[12px] ml-1 text-green-600'>

            (write each feature on a different line)
            </span>
            </label>
            <textarea
            rows={5} 
            value={description}
            onChange={handleInputChange}
            type="text" name="description" placeholder='Add product details' id=""
            className={`font-satoshi text-[14px] px-4 py-2 rounded-md outline-none ${description.length > 0 ? 'border-2 border':'border'}`} />
          </div>
                    {/* <div className="w-full flex flex-col gap-1">
                      <label htmlFor="description" className='font-satoshi text-[14px]'>Product description</label>
                      <textarea
                      value={productDescription}
                      onChange={(e) => setAddProductDescription(e.target.value)} name='description' 
                      placeholder='Add product description'
                      
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div> */}
                    <div className="items-start w-full flex flex-col gap-1">
                      <label htmlFor="featured" className='font-satoshi text-[14px]'>Product featured</label>
                      <input
                      value={productFeatured}
                      onChange={(e) => setAddProductFeatured(e.target.checked)} name='featured'
                      type="checkbox" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="items-start w-full flex flex-col gap-1">
                      <label htmlFor="featured" className='font-satoshi text-[14px]'>Customers to enquire price</label>
                      <input
                      checked={productenquire}
                      value={productenquire}
                      onChange={(e) => setAddProductEnquire(e.target.checked)} name='enquire'
                      type="checkbox" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    {/* <div className="w-full flex flex-col gap-1">
                      <label htmlFor="Newprice" className='font-satoshi text-[14px]'>Product thumbnail url</label>
                      <input
                      value={productThumbnailUrl}
                      onChange={(e) => setAddProductThumbnailUrl(e.target.value)} name='thumbnail' 
                      placeholder='Add product thumbnail url'
                    
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div> */}
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                  onClick={handleSubmit}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>

                        {processing? 'Saving...': 'Continue'}
                        </button>
                  <button
                    // onClick={() => setAddProductModal(false)}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>

                </div>
                </div>
              </div>

              <ToastContainer />

    </>
  )
}

export default AddProductPage
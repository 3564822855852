import React from "react";

function DateTimeComponent({ createdDateTime }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const createdDate = new Date(createdDateTime);
  const day = createdDate.getDate();
  const month = months[createdDate.getMonth()];
  const year = createdDate.getFullYear();

  return <div>{`${day}, ${month} ${year}`}</div>;
}

export default DateTimeComponent;
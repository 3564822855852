import './App.css';
import { Route, Routes } from 'react-router-dom';
import { ENDPOINT_ADD_PRODUCT, ENDPOINT_CATEGORIES, ENDPOINT_CATEGORy_DETAILS, ENDPOINT_CONTACTS, ENDPOINT_DASHBOARD, ENDPOINT_ENQUIRIES, ENDPOINT_ORDERS, ENDPOINT_ORDER_DETAILS, ENDPOINT_PRODUCTS, ENDPOINT_PRODUCT_DETAILS, LOGIN_ENDPOINT, ProtectedRoute } from './routes';
import HomePage from './pages/HomePage';
import Layout from './components/shared/Layout';
import ProductsPage from './pages/ProductsPage';
import CategoriesPage from './pages/CategoriesPage';
import { ToastContainer } from 'react-toastify';
import CategoryDetailsPage from './pages/CategoryDetailsPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import OrdersPage from './pages/OrdersPage';
import OrderDetails from './pages/OrderDetails';
import AddProductPage from './pages/AddProductPage';
import NotFound from './pages/NotFound';
import Enquiries from './pages/Enquiries';
import MainLayout from './components/shared/MainLayout';
import Login from './pages/Login';
import Messages from './pages/Messages';

function App() {

  return (
    < >
    <Routes>
    <Route path={ENDPOINT_DASHBOARD}  element={
      <ProtectedRoute>

        <MainLayout />
      </ProtectedRoute>
      }>
    <Route index element={<HomePage/>}/>
      <Route element={<ProductsPage />} path={ENDPOINT_PRODUCTS} />
      <Route element={<CategoriesPage />} path={ENDPOINT_CATEGORIES} />
      <Route element={<CategoryDetailsPage />} path={ENDPOINT_CATEGORy_DETAILS} />
      <Route element={<ProductDetailsPage />} path={ENDPOINT_PRODUCT_DETAILS} />
      <Route element={<OrdersPage />} path={ENDPOINT_ORDERS} />
      <Route element={<OrderDetails />} path={ENDPOINT_ORDER_DETAILS} />
      <Route element={<AddProductPage />} path={ENDPOINT_ADD_PRODUCT} />
      <Route element={<Enquiries />} path={ENDPOINT_ENQUIRIES} />
      <Route element={<Messages />} path={ENDPOINT_CONTACTS} />


      </Route>
      <Route element={<NotFound />} path='*' />
      <Route element={<Login />} path={LOGIN_ENDPOINT} />


    </Routes>

    <ToastContainer />

    </>
  );
}

export default App;
